import axios from "axios"

// use "shopifyConfig.url" as the base url
//
const config = require("../../../.runtimeconfig.json").config
const shopifyConfig = config?.shopify[process.env.NODE_ENV]
const baseUrl = process.env.NODE_ENV === "production" ? shopifyConfig.url : `${shopifyConfig.url}/api`


export const getCustomerPlan = async (shop, shopifyCustomerId, shopifyOrderId) => {
  const response = await axios.get(`${baseUrl}/api/customers/${shopifyCustomerId}/payment-plans/${shopifyOrderId}`, {
    headers: { "x-shopify-shop-domain": shop },
  })

  if (response.data.status !== "success") {
    console.log(`[ERROR]: ${response.data.body}`)
    return null
  }

  return response.data.body
}

export const putCustomerPlan = async (shop, shopifyCustomerId, shopifyOrderId, lineItems, variants, discounts, paymentDate) => {
  const response = await axios.put(
    `${baseUrl}/api/customers/${shopifyCustomerId}/payment-plans/${shopifyOrderId}`,
    {
      lineItems,
      variants,
      discounts,
      paymentDate,
    },
    {
      headers: { "x-shopify-shop-domain": shop },
    }
  )

  if (response.data.status !== "success") {
    console.log(`[ERROR]: ${response.data.body}`)
    return null
  }

  return response.data.body
}

export const cancelCustomerPlan = async (shop, shopifyCustomerId, shopifyOrderId) => {
  const response = await axios.delete(`${baseUrl}/api/customers/${shopifyCustomerId}/payment-plans/${shopifyOrderId}`, {
    headers: { "x-shopify-shop-domain": shop },
  })

  if (response.data.status !== "success") {
    console.log(`[ERROR]: ${response.data.body}`)
    return null
  }

  return response.data.body
}
