import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"
import createApp from "@shopify/app-bridge"
import { Redirect } from "@shopify/app-bridge/actions"
import { RoutePropagator, TitleBar } from "@shopify/app-bridge-react"
import { Page, PageActions, Frame, Loading, Modal, TextContainer } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

import { getCustomerPlan, putCustomerPlan, cancelCustomerPlan } from "../../service/payment-plan"

import { PaymentPlanForm } from "components/Payment/PaymentPlanForm"

const config = require("../../../../.runtimeconfig.json").config
const shopifyConfig = config?.shopify[process.env.NODE_ENV]
const braintreeConfig = config?.braintree[process.env.NODE_ENV]

const app = createApp({
  apiKey: shopifyConfig.api_key,
  shopOrigin: ReactifyApp.Auth.getShop(),
})

const redirect = Redirect.create(app)

const ManagePaymentPlanPage = props => {
  const { location, customerId, orderId, firebase, shop } = props
  const [modalActive, setModalActive] = useState(false)
  const [plan, setPlan] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)

  useEffect(() => {
    if (orderId && customerId && shop) {
      const fetchPlan = async () => {
        const result = await getCustomerPlan(shop, customerId, orderId)
        setPlan(result)
      }
      fetchPlan()
    }
  }, [customerId, orderId, shop])

  const handleModalChange = useCallback(() => setModalActive(!modalActive), [modalActive])

  const handleUpdatePlan = async ({ lineItems, variants, discounts, paymentDate }) => {
    setIsUpdating(true)

    const newPlan = await putCustomerPlan(shop, customerId, orderId, lineItems, variants, discounts, paymentDate)

    setPlan(newPlan)
    setIsUpdating(false)

    return newPlan
  }

  const handleCancelPlan = async () => {
    setIsCancelling(true)
    await cancelCustomerPlan(shop, customerId, orderId)
    setIsCancelling(false)
    navigate("/payment/list")
  }

  const config = {
    title: plan ? "Plan Details" : "",
    breadcrumbs: [{ content: "Plans", onAction: () => navigate("/payment/list") }],
    secondaryActions: [{ content: "Back", onAction: () => navigate("/payment/list") }],
  }

  return (
    <Page title={config.title}>
      {location && <RoutePropagator location={location} />}
      <TitleBar {...config} />

      {plan ? (
        <>
          <PaymentPlanForm
            plan={plan}
            onUpdatePlan={handleUpdatePlan}
            isUpdating={isUpdating}
            redirect={redirect}
            subscriptionUrl={`${braintreeConfig.url}/merchants/${braintreeConfig.merchantId}/subscriptions`}
            transactionUrl={`${braintreeConfig.url}/merchants/${braintreeConfig.merchantId}/transactions`}
            firebase={firebase}
            shop={shop}
          />
          <div style={{ marginTop: "25px" }}>
            <PageActions
              secondaryActions={[
                {
                  content: "Cancel Payment Plan",
                  destructive: true,
                  onAction: handleModalChange,
                  disabled: plan.status === "Canceled",
                },
              ]}
            />
          </div>
        </>
      ) : (
        <div style={{ height: "100px" }}>
          <Frame>
            <Loading />
          </Frame>
        </div>
      )}

      <Modal
        open={modalActive}
        onClose={handleModalChange}
        title="Cancel Payment Plan?"
        primaryAction={{
          content: "YES, CONTINUE",
          onAction: handleCancelPlan,
          destructive: true,
          loading: isCancelling,
        }}
        secondaryActions={[
          {
            content: "NO, CANCEL",
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{`Both Shopify order and Braintree subscription will be cancelled.`}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default ReactifyApp.Firebase.withFirebase(ManagePaymentPlanPage)
