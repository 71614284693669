import React from "react"
import { Card, DataTable, Link } from "@shopify/polaris"

import * as utils from "../../helpers/utils"

export const PaymentPlanTransactionList = ({ plan, transactionUrl }) => {
  const statusMapping = {
    settled: "Processed",
    settling: "Pending",
  }

  const transactions = plan.transactions.map(transaction => [
    <Link url={`${transactionUrl}/${transaction.id}`} external>
      {utils.formatDate(transaction.createdAt)}
    </Link>,
    utils.toTitleCase(transaction.paymentType),
    utils.toTitleCase(statusMapping[transaction.status] || transaction.status),
    `$${transaction.amount}`,
  ])

  return (
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Card.Header title="Transaction History"></Card.Header>
        <Card.Section>
          <DataTable
            columnContentTypes={["text", "text", "text", "numeric"]}
            headings={["Date", "Type", "Status", "Amount"]}
            rows={transactions}
            showTotalsInFooter
          />
        </Card.Section>
      </Card>
    </div>
  )
}
